<template>
  <div>
   
  </div>
</template>

<script >

export default {
  data() {
    return {
    };
  },
  created(){
    this.OssSignIn()
  },
  methods: {
    OssSignIn(){
      let url =  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1a86754b82cee013&redirect_uri=http://mp.91workman.com/auth?page=select%26pid="+this.getQueryVariable("pid")+"%26userType=CUSTOM&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
      // let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1a86754b82cee013&redirect_uri=http%3A%2F%2Fmp.91workman.com%2Fauth%26response_type%3Dcode%26scope%3Dsnsapi_userinfo%26state%3D123%23wechat_redirect"
      // let authUrl = `${window.location.protocol}//${window.location.host}/auth`
      console.info("login OssSignIn",this.getQueryVariable("pid"))
      window.location.href=url
    },
    getQueryVariable(variable)
    {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
      }
      return("");
    }
  },
};
</script>


<style scoped>
.contentLogin {
  width: 500px;
  height: 300px;
  box-sizing: border-box;
  padding: 0 50px;
  border-radius: 5px;
  box-shadow: 0px 2px 12px 0px rgba(105, 105, 105, 0.07);
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: mymove 1s ease-in-out alternate;
  overflow: hidden;
  transition: 1.5s;
}

@keyframes mymove {
  0% {
    width: 0px;
    height: 5px;
  }

  10% {
    width: 50px;
    height: 5px;
  }

  15% {
    width: 100px;
    height: 5px;
  }

  20% {
    width: 150px;
    height: 5px;
  }

  25% {
    width: 200px;
    height: 5px;
  }

  30% {
    width: 250px;
    height: 5px;
  }

  35% {
    width: 300px;
    height: 5px;
  }

  40% {
    width: 350px;
    height: 5px;
  }

  45% {
    width: 450px;
    height: 5px;
  }

  50% {
    width: 500px;
    height: 5px;
  }

  55% {
    height: 30px;
  }

  60% {
    height: 60px;
  }

  65% {
    height: 90px;
  }

  70% {
    height: 120px;
  }

  75% {
    height: 150px;
  }

  80% {
    height: 180px;
  }

  85% {
    height: 210px;
  }

  90% {
    height: 240px;
  }

  95% {
    height: 240px;
  }

  100% {
    height: 300px;
  }
}

.content_input {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content_button>button{
  margin-top: 10px;
}


.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
  color: #606266;
}


</style>